<template>
  
  <v-row class="px-2 py-5">
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="3" class="pb-0">
          <analytic-card
            title="Miembros Activos"
            :color="homologateStatusWithColor('ACTIVE').color"
            :description="indicators.activeMembers"
          />
        </v-col>
        <v-col cols="12" md="3" class="pb-0">
          <analytic-card
            title="Miembros Inactivos"
            :color="homologateStatusWithColor('INACTIVO').color"
            :description="indicators.inactiveMembers"
          />
        </v-col>
        <v-col cols="12" md="3" class="pb-0">
          <analytic-card
            title="Prospectos"
            :color="homologateStatusWithColor('PROSPECTO').color"
            :description="indicators.prospectMembers"
          />
        </v-col>
        <v-col cols="12" md="3" class="pb-0">
          <analytic-card
            title="Miembros Expirados"
            :color="homologateStatusWithColor('EXPIRED').color"
            :description="indicators.expiredMembers"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-row class="mt-5 px-5">
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 10 : 4">
        <div class="searchbar d-flex flex-row my-4">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                small
                elevation="0"
                :loading="searchBarLoader"
                class="rounded-pill rounded-r-0"
                color="#feb300"
                @click="filter(), (eventsSearchData.name = '')"
              >
                <v-icon size="20">mdi-magnify</v-icon></v-btn
              >
            </template>
            Click para buscar un miembro
          </v-tooltip>
          <input
            :style="
              $vuetify.breakpoint.smAndDown ? ' width: 15rem ' : 'width:35rem'
            "
            type="text"
            placeholder="Buscar miembro"
            class="busqueda rounded-pill rounded-l-0"
            v-model="eventsSearchData.name"
            v-on:keypress.enter="filter(), (eventsSearchData.name = '')"
          />
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 10 : 2">
        <div class="searchbar d-flex flex-row my-4" :style="'height: 42px;'">
          <v-select
            :items="items_validate_status"
            :style="
              $vuetify.breakpoint.smAndDown ? ' width: 15rem ' : 'width:35rem'
            "
            clearable
            class="busqueda my-0 py-0 pl-2 pr-5"
            placeholder="Estatus"
            v-model="eventsSearchData.validationStatus"
            @change="filter()"
          />
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 10 : 3">
        <div class="searchbar d-flex flex-row my-4" :style="'height: 42px;'">
          <v-select
            :items="items_roles"
            :style="
              $vuetify.breakpoint.smAndDown ? ' width: 15rem ' : 'width:35rem'
            "
            clearable
            class="busqueda my-0 py-0 pl-2 pr-5"
            placeholder="Tipo de membresía"
            v-model="eventsSearchData.role"
            @change="filter()"
          />
        </div>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.smAndDown ? 2 : 3"
        class="d-flex align-center"
        :class="
          $vuetify.breakpoint.smAndDown ? 'justify-end' : 'justify-center'
        "
        v-if="user.permissions.Membership && user.permissions.Membership.update==true"
      >
        <MemberUserSection
          :route="this.$route.fullPath"
          :create="true"
          @save="saveInfo"
        />
      </v-col>
    </v-row>

    <v-row class="displayW">
      <v-pagination
        v-model="eventsSearchData.page"
        :length="membersPaginated.totalPages || 1"
        @input="filtrarMiembros()"
        total-visible="10"
        style="width: 100%"
        class="my-2"
      >
      </v-pagination>
      <v-card class="displayW2">
        <v-list>
          <v-list-group
            v-for="item in arrListMemberInformation"
            :key="item._id"
            no-action
            :append-icon="$vuetify.breakpoint.smAndDown ? '' : '$expand'"
            style="border: 1px solid lightgray"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                    <v-row class="ma-0">
                      <v-col cols="auto">
                        <v-chip
                          :color="
                            homologateStatusWithColor(item.validationStatus)
                              .color
                          "
                          class="rounded-lg font-weight-bold"
                        >
                          {{
                            homologateStatusWithColor(item.validationStatus)
                              .status
                          }}
                        </v-chip>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <span
                          class="ma-0"
                          style="font-weight: 800; text-transform: uppercase"
                          >{{ item.list.name || "N/D" }} -
                          {{ item.list.lastName || "N/D" }}</span
                        >
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span
                            class="ma-0"
                            style="font-weight: 800; text-transform: uppercase"
                          >
                            Tipo de membresía -
                          </span>
                          {{ setRole(item.allInformation.role) }}
                        </p>
                      </v-col>
                      <!-- <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass">
                            F. Expiración -
                          </span>
                          N/D
                        </p>
                      </v-col> -->
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass">
                            Razón Social -
                          </span>
                          {{ item.drawer.compania || "N/D" }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass"
                            >RFC - </span
                          >{{ item.drawer.rfc || "N/D" }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass"
                            >E-MAIL - </span
                          >{{
                            item.moreInfo.email || item.drawer.email || "N/D"
                          }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass"
                            >CAPÍTULO - </span
                          >{{ item.moreInfo.Capítulo || "N/D" }}
                        </p>
                      </v-col>
                      <v-col v-if="item.allInformation.hasOwnProperty('memberOf')" cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass"
                            >NIVEL DE MEMBRESÍA - </span
                          >{{ item.allInformation.memberOf.affiliationType|| "N/D" }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- cuadro para visualizar o descargar documentos 8 4-->
                  <v-col
                    :cols="$vuetify.breakpoint.smAndDown ? 9 : 3"
                    class="d-flex align-center flex-row-reverse"
                  >
                    <v-dialog
                      transition="dialog-bottom-transition"
                      width="700"
                      style="border-radius: 0px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#b3e5fc"
                          class="text-capitalize rounded-pill"
                          style="font-size: 17px; font-weight: 800"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="mx-2"> mdi-folder-outline</v-icon>
                          Documentos
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card
                          :class="
                            $vuetify.breakpoint.smAndDown ? 'pa-9' : 'pa-14'
                          "
                          style="border-radius: 0px"
                          color="white"
                        >
                          <v-row class="mb-5">
                            <v-col cols="auto" class="py-2">
                              <v-chip
                                :color="
                                  homologateStatusWithColor(
                                    item.validationStatus
                                  ).color
                                "
                                class="rounded-lg font-weight-bold"
                              >
                                {{
                                  homologateStatusWithColor(item.validationStatus).status
                                }}
                              </v-chip>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <span
                                class="ma-0"
                                style="
                                  font-weight: 800;
                                  text-transform: uppercase;
                                "
                                >{{ item.list.name || "N/D" }} -
                                {{ item.list.lastName || "N/D" }}</span
                              >
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  class="textclass"
                                >
                                  F. Expiración -
                                </span>
                                N/D
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  class="textclass"
                                >
                                  Razón Social -
                                </span>
                                {{ item.drawer.compania || "N/D" }}
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  class="textclass"
                                  >RFC - </span
                                >{{ item.drawer.rfc || "N/D" }}
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  class="textclass"
                                  >E-MAIL - </span
                                >{{ item.moreInfo.email || "N/D" }}
                              </p>
                            </v-col>
                            <v-col cols="auto" class="d-flex align-center py-2">
                              <p class="ma-0">
                                <span
                                  class="textclass"
                                  >CAPÍTULO - </span
                                >{{ item.moreInfo.Capítulo || "N/D" }}
                              </p>
                            </v-col>
                          </v-row>
                          <v-card-text class="px-0">
                            <span
                              style="
                                color: #35404c;
                                font-weight: 900;
                                font-size: 18px;
                              "
                              >Lista de documentos</span
                            >
                            <v-divider
                              class="mt-1"
                              style="
                                background: #35404c;
                                border: 1px solid #35404c;
                              "
                            />
                            <v-list>
                              <v-list-item-group>
                                <v-list-item
                                  dense
                                  v-for="doc in Object.entries(item.documents)"
                                  :key="doc"
                                >
                                  <v-list-item-content>
                                    <span
                                      v-text="
                                        doc[0] == 'ine'
                                          ? 'INE'
                                          : doc[0] == 'comprobanteDomicilio'
                                          ? 'COMPROBANTE DE DOMICILIO'
                                          : doc[0] ==
                                            'constanciaSituacionFiscal'
                                          ? 'CONSTANCIA SITUACIÓN FISCAL'
                                          : 'LOGO DE EMPRESA'
                                      "
                                      style="
                                        font-size: 15px;
                                        color: #35404c;
                                        font-weight: 800;
                                      "
                                    ></span>
                                  </v-list-item-content>
                                  <v-list-item-action
                                    class="d-flex flex-row justify-space-between"
                                  >
                                    <v-dialog
                                      fullscreen
                                      hide-overlay
                                      transition="dialog-bottom-transition"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="
                                            doc[1] == null ||
                                            doc[1] == 'NOT_ASSIGNED' ||
                                            doc[1] == ''
                                          "
                                          small
                                          fab
                                          elevation="0"
                                          class="mr-2 rounded-pill"
                                        >
                                          <v-icon>mdi-eye-outline</v-icon>
                                        </v-btn>
                                      </template>
                                      <template v-slot:default="dialog">
                                        <v-btn
                                          v-if="
                                            $vuetify.breakpoint.smAndDown !=
                                            true
                                          "
                                          @click="dialog.value = false"
                                          bottom
                                          right
                                          absolute
                                          fab
                                          color="accent"
                                          style="
                                            position: absolute;
                                            bottom: 50px;
                                            right: 50px;
                                          "
                                          ><v-icon>mdi-close</v-icon></v-btn
                                        >
                                        <v-btn
                                          v-else
                                          @click="dialog.value = false"
                                          large
                                          bottom
                                          ><v-icon>mdi-close</v-icon></v-btn
                                        >
                                        <iframe
                                          v-if="doc[1].includes('.pdf')"
                                          type="application/pdf"
                                          :src="
                                            'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
                                            doc[1]
                                          "
                                          style="width: 100%; height: 100%"
                                        ></iframe>
                                        <v-card
                                          v-else
                                          width="20%"
                                          height="20%"
                                          class="d-flex align-center justify-center"
                                        >
                                          <v-img
                                            :src="doc[1]"
                                            width="auto"
                                            height="auto"
                                            max-height="90%"
                                            max-width="90%"
                                          />
                                        </v-card>
                                      </template>
                                    </v-dialog>

                                    <v-btn
                                      :disabled="
                                        doc[1] == null ||
                                        doc[1] == 'NOT_ASSIGNED' ||
                                        doc[1] == ''
                                      "
                                      :href="doc[1]"
                                      fab
                                      small
                                      class="rounded-pill"
                                      elevation="0"
                                    >
                                      <v-icon> mdi-download-outline </v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card-text>
                          <v-card-actions class="d-flex justify-end">
                            <v-btn
                              @click="dialog.value = false"
                              elevation="0"
                              style="font-size: 18px; font-weight: 800"
                              class="text-capitalize rounded-pill"
                            >
                              <v-icon color="#35404c">mdi-chevron-left</v-icon>
                              <span style="color: #35404c" class="pr-2"
                                >Volver</span
                              >
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.smAndDown ? 3 : 1"
                    class="d-flex justify-center align-center"
                    v-if="user.permissions.Membership && user.permissions.Membership.update == true"
                  >
                    <!-- @click="editItem(item)"           @click="getform()" -->

                    <MemberUserSection
                      route="/miembros"
                      :update="true"
                      :miembroAllInfo="item.allInformation"
                      @save="saveInfo"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </template>

            <v-row class="pa-2 pl-7">
              <v-col
                :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
                class="py-1"
                v-for="(item, value, index) in item.drawer"
                :key="index"
              >
                <p>
                  <span v-if="item_labels_name.hasOwnProperty(value)">
                    <strong>{{
                      item_labels_name[value]
                        .replaceAll("_", " ")
                        .toUpperCase() || "N/D"
                    }}</strong
                    ><br />
                    {{
                      value == "memberFrom" || value == "updatedAt"
                        ? item.split("T")[0]
                        : item || "N/D"
                    }}
                  </span>
                  <span v-else>
                    <strong>{{
                      value.replaceAll("_", " ").toUpperCase() || "N/D"
                    }}</strong
                    ><br />
                    {{ item || "N/D" }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-list-group>
        </v-list>
      </v-card>
    </v-row>
    <v-snackbar
      :multi-line="true"
      :top="true"
      :right="true"
      :timeout="time"
      v-model="isVisible"
      :color="color"
      style="z-index: 249"
      class="rounded-xl"
      transition="slide-x-reverse-transition"
    >
      <span class="subtitle-2 font-weight-bold">{{ text }}</span>
    </v-snackbar>
    
  </v-row>
</template>

<script>
import { validations } from "@/share/validations.js";
import AnalyticCard from "@/components/dashboard/event/analytics/analytic-card";
import { mapState, mapMutations, mapActions } from "vuex";
import MemberForm from "@/components/shared/MemberForm";
import MemberUserSection from "@/components/memberDashboard/memberUserSection.vue";
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";

export default {
  components: {
    AnalyticCard,
    MemberForm,
    MemberUserSection,
  },
  computed: {
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
    ...mapState("members", ["members", "membersPaginated"]),
    ...mapState("notification", ["text", "color", "time", "visible"]),
    isVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.hide();
      },
    },
    isAmpi() {
      //dev ampi: 62b622c66a7441ae20159d7d
      const ampi1 = "62b622c66a7441ae20159d7";
      const ampi2 = "6297b0fec7bf8b6f826313be";
      return this.user.headOffice === ampi1 || this.user.headOffice === ampi2;
    },
  },
  data: () => ({
    isChapter: false,
    searchBarLoader: false,
    dialogMember: false,
    activator: null,
    attach: null,
    editing: null,
    showPass: false,
    editingIndex: -1,
    indicators: {
      activeMembers: "0",
      inactiveMembers: "0",
      prospectMembers: "0",
      expiredMembers: "0",
    },
    item_labels_name: {
      company: "compañía",
      createdAt: "Fecha de creación",
      updatedAt: "Fecha de actualización",
      gender: "Género",
      maximum_degree_of_studies: "Grado máximo de estudios",
      pagina_web: "Web",
      correo_electronico_de_contacto: "Correo",
      fecha_de_cumpleanos: "Fecha de nacimiento",
      codigo_postal: "Código postal",
      numero_de_telefono: "Teléfono fijo",
      numero_de_celular: "Teléfono móvil",
      memberFrom: "Miembro desde",
      memberOf: "Submiembro de",
    },
    drawer_fields_excluded: [
      "ine",
      "comprobanteDomicilio",
      "companyImage",
      "localidad",
      "constanciaSituacionFiscal",
      "firstLastname",
      "memberForm",
      "createdAt",
      "validationStatus",
    ],
    items_validate_status: [
      { text: "Prospecto", value: "PROSPECTO" },
      { text: "Activo", value: "ACTIVE" },
      { text: "Inactivo", value: "INACTIVO" },
      { text: "Expirado", value: "EXPIRED" },
    ],
    items_roles: [
      { text: "Asociado", value: "MEMBER" },
      { text: "Afiliado", value: "MEMBERSHIP_USER" },
    ],
    region: [
      {
        text: "Norte",
      },
      {
        text: "Centro",
      },
      {
        text: "Sur",
      },
    ],
    headers: [
      { text: "", value: "checkbox", sortable: false },
      { text: "", value: "validationStatus", sortable: false },
      { text: "", value: "name", sortable: false },
      {
        text: " ",
        align: "start",
        value: "email",
        sortable: false,
      },
      { text: "", value: "company", sortable: false },
      //{ text: " ", value: "region", sortable: false },
      { text: " ", value: "branch", sortable: false },
      { text: " ", value: "actions", sortable: false },
    ],
    memberBranch: "",
    branches: [],
    nonce: 1,
    menu: false,
    model: [
      {
        text: "AMPI - Guanajuato ",
      },
    ],
    nameRules: validations.nameValidation({ name: "nombre", required: true }),
    companyRules: validations.nameValidation({
      name: "company",
      required: true,
    }),
    firstLastnameRules: validations.nameValidation({
      name: "primer apellido",
      required: true,
    }),
    emailRules: validations.emailValidation(),
    passwordRules: validations.generalFValidation({
      required: true,
      minLength: 8,
      name: "contraseña",
    }),
    memberData: {
      id: "",
      name: "",
      firstLastname: "",
      email: "",
      company: "",
      region: "",
      branch: "",
    },
    memberDocuments: [],
    boolEdit: false,
    x: 0,
    search: null,
    y: 0,
    eventsSearchData: {
      name: "",
      branch: "",
      role: "",
      page: 1,
      perPage: 10,
      validationStatus: "",
    },
    dialogDelete: false,
    alertBool: false,
    drawerMemberInformation: [],
    listMemberInformation: [],
    arrListMemberInformation: [],
    moreMemberInformation: [],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },

  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show", "hide"]),
    ...mapMutations("members", ["clearmembersPaginated"]),
    ...mapActions("members", [
      "getMembers",
      "createMember",
      "getMemberIndicators",
      "getChapterIndicators",
      "getBranchMembersPaginated",
      "updateMember",
      "deleteMember",
    ]),
    ...mapActions("branches", [
      "getBranches",
      "createBranchMember",
      "updateBranchMember",
    ]),
    homologateStatusWithColor,
    async fetchGraphData() {
      try {
        this.loading();
        const response = await this.axios.get("/users/graph-indicators");
        console.log(response);
        this.indicators.activeMembers = response.data.indicators.activeMembers;
        this.indicators.inactiveMembers =
          response.data.indicators.inactiveMembers;
        this.indicators.prospectMembers =
          response.data.indicators.prospectMembers;
        this.indicators.expiredMembers =
          response.data.indicators.expiredMembers;
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    // close() {
    //   this.dialogMember = false;
    // },
    // open() {
    //   this.dialogMember = true;
    // },

    deleteItem(id) {
      this.dialogDelete = true;
      this.memberData.id = id;
    },

    async deleteItemConfirm() {
      this.loading();
      let id = this.memberData.id;
      const response = await this.deleteMember(id);

      if (response.status === true) {
        this.closeDelete();
        this.memberData.id = "";
        this.loaded();
        this.show({
          text: "Miembro eliminado exitosamente!",
          color: "success",
        });
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    nextPage() {
      this.eventsSearchData.page++;
      this.filter();
    },

    async fetchBranches() {
      const response = await this.getBranches();

      if (response.status === 200) {
        response.branches.forEach((br) => {
          this.branches.push({
            text: br.company,
            _id: br._id,
          });
        });
      }
    },

    async fetchIndicators() {
      let response = null;

      if (this.$route.params.chapterId) {
        response = await this.getChapterIndicators(
          this.$route.params.chapterId
        );
      } else {
        response = await this.getMemberIndicators();
      }

      if (response.status == true) {
        this.indicators = {
          activeMembers: response.data.activeMembers.toString(),
          inactiveMembers: response.data.inactiveMembers.toString(),
          prospectMembers: response.data.prospectMembers.toString(),
          expiredMembers: response.data.expiredMembers.toString(),
          totalMembers: response.data.totalMembers.toString(),
        };
      }
    },

    filter() {
      this.eventsSearchData.page = 1;
      this.filtrarMiembros();
    },

    async filtrarMiembros() {
      this.loading();
      this.searchBarLoader = true;
      if (this.isChapter) {
        this.eventsSearchData.branch = this.$route.params.chapterId;
      }
      this.clearmembersPaginated();
      const response = await this.getBranchMembersPaginated(
        this.eventsSearchData
      );
      if (response.status === true) {
        if (this.membersPaginated.members) {
          this.arrListMemberInformation = [];

          this.membersPaginated.members.forEach((m) => {
            if (m.memberInformation) {
              this.listMemberInformation = {
                name: m.memberInformation.name,
                lastName: m.memberInformation.lastName,
              };

              this.moreMemberInformation = {
                Capítulo: m.hasOwnProperty("branch") ? m.branch.company : "N/A",
                email: m.memberInformation.email,
                sub_member_limit: m.memberInformation.sub_member_limit,
                sub_members_used: m.memberInformation.sub_members_used,
              };

              this.drawerMemberInformation = Object.keys(
                m.memberInformation
              ).reduce((result, key) => {
                !Object.keys(this.listMemberInformation).includes(key) &&
                !Object.keys(this.moreMemberInformation).includes(key) &&
                !this.drawer_fields_excluded.includes(key)
                  ? (result[key] = m.memberInformation[key])
                  : "";
                return result;
              }, {});
              if (m.membership) {
                this.drawerMemberInformation["memberOf"] = m.membership.email;
              }

              this.arrListMemberInformation.push({
                company: m.company,
                validationStatus: m.validationStatus,
                _id: m._id,
                list: this.listMemberInformation,
                drawer: this.drawerMemberInformation,
                moreInfo: this.moreMemberInformation,
                documents: {
                  ine: m.memberInformation.ine,
                  comprobanteDomicilio:
                    m.memberInformation.comprobanteDomicilio,
                  constanciaSituacionFiscal:
                    m.memberInformation.constanciaSituacionFiscal,
                  companyImage: m.memberInformation.companyImage,
                },
                allInformation: m,
              });
            }
          });
          console.log(this.arrListMemberInformation);
          this.loaded();
          this.searchBarLoader = false;
        }
      } else {
        this.loaded();
        this.searchBarLoader = false;
        console.log("Error en la petición: " + response.message);
      }
    },

    setBranch(v) {
      this.memberData.branch = {
        _id: v._id,
        company: v.text,
      };
    },

    setRole(role) {
      const itemRole = this.items_roles.find((e) => e.value == role);
      return itemRole.text;
    },

    async saveInfo(userData, id, status, updateFiles) {
      // this.loading();
      if (status == "update") {
        let up = {
          memberInformation: userData.memberInformation,
        };
        let response;
        if (this.user.role == "BRANCH") {
          response = await this.updateBranchMember({ userData: up, id: id });
        } else {
          response = await this.updateMember({ userData: up, id: id });
        }
        console.log(response.message);
        if (response.status === true) {
          this.loaded();
          this.show({
            text: "Miembro actualizado exitosamente!",
            color: "success",
            time: 3000,
          });
        }
        setTimeout(() => {
          this.filter();
        }, 2000);
      } else {
        // this.memberData.region = this.memberData.region.text;
        let response;
        if (this.user.role == "BRANCH") {
          response = await this.createBranchMember({
            memberData: userData,
            branchId: this.user._id,
          });
        } else {
          response = await this.createMember(userData);
        }
        console.log(response.message);
        if (response.status === true) {
          console.log(response);
          await updateFiles(response.data.data.user._id);
          this.loaded();
          this.show({
            text: "¡Miembro agregado exitosamente!",
            color: "success",
          });
          setTimeout(() => {
            this.filter();
          }, 2000);
        } else {
          this.loaded();
          this.show({
            text: "¡Ha ocurrido un problema al agregar el miembro, o ya existe!",
            color: "error",
          });
        }
      }
    },
  },
  destroyed() {
    this.clearmembersPaginated();
  },
  async mounted() {
    await this.fetchIndicators();
    await this.filter();
    await this.fetchBranches();
  },
  created() {
    if (this.$route.params.chapterId) {
      this.isChapter = true;
    }
  },
};
</script>

<style>
.colorAccent1 {
  background-color: #fad0bf !important;
}

.colorAccent2 {
  background-color: #ffecb3 !important;
}

.colorAccent3 {
  background-color: #ffe0b2 !important;
}

.colorAccent4 {
  background-color: #cfd8dc !important;
}

.accent--text::before {
  color: #000000 !important;
}

.noShadow {
  box-shadow: none;
}

.tittlePermit {
  margin-top: 4%;
}

.bluetext {
  color: #141d30;
}

.textwhite {
  color: #ffffff !important;
}

.displayW {
  width: 100% !important;
  margin-top: 15px;
}

.displayW2 {
  width: 100% !important;
  margin-inline: 20px;
}

.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px !important;
}

.box > * {
  flex: 0 0 33.3333%;
}

.textclass {
  color: #35404c; font-weight: bold
}

/* iPhone */
@media (max-width: 875.98px) {
  b {
    font-size: 0.8em !important;
    white-space: normal;
  }

  .v-list-item__title {
    font-size: 0.8rem !important;
    white-space: normal;
  }

  .v-chip {
    font-size: 0.6rem !important;
    white-space: normal;
  }
}

.busqueda {
  padding-left: 20px;
  border: 0;
  border-color: transparent;
  background-color: #f9fafc;
}

.busqueda:focus {
  outline: none;
  border: 1px solid transparent;
}
</style>