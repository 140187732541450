<template>
  <PersonalizedDialog
    :dialog="dialog"
    hideName
    hideImage
    :dimentions="{ width: 700 }"
    @discard="closeDialog()"
  >
    <template #alertContent>
      <v-card
        class="px-4 py-2"
        style="border-radius: 0px"
        color="white"
        width="100%"
        elevation="0"
      >
        <v-row class="mb-5">
          <v-col cols="auto" class="py-2">
            <v-chip
              :color="homologateStatusWithColor(member.validationStatus).color"
              class="rounded-lg font-weight-bold"
            >
              {{ homologateStatusWithColor(member.validationStatus).status }}
            </v-chip>
          </v-col>
          <v-col cols="auto" class="d-flex align-center py-2">
            <span
              class="ma-0"
              style="font-weight: 800; text-transform: uppercase"
              >{{ member.name || 'N/D' }} - {{ member.lastName || 'N/D' }}</span
            >
          </v-col>
          <v-col cols="auto" class="d-flex align-center py-2">
            <p class="ma-0">
              <span class="textclass">E-MAIL - </span
              >{{ member.email || 'N/D' }}
            </p>
          </v-col>
          <v-col
            cols="auto"
            class="d-flex align-center py-2"
            v-if="user.hierarchyType == 'TWO_LEVELS'"
          >
            <p class="ma-0">
              <span class="textclass">CAPÍTULO - </span
              >{{ member.branch ? member.branch.company || 'N/D' : 'N/D' }}
            </p>
          </v-col>
        </v-row>
        <v-card-text class="px-0">
          <v-row>
            <v-col cols="12">
              <span style="color: #35404c; font-weight: 900; font-size: 18px"
                >Cuota a facturar</span
              >
              <v-divider
                class="mt-1"
                style="background: #35404c; border: 1px solid #35404c"
              />
              <v-simple-table
                v-if="Object.values(getMembership).some((element) => element)"
              >
                <tbody>
                  <tr background-color="red">
                    <td class="text-bold">
                      {{ getMembership.name }}
                    </td>
                    <td class="text-end">
                      {{  formatPrice(getMembership.price)  }}
                    </td>
                    <td :class="tableRowClass" v-if="canGenerateInvoice">
                      <v-btn
                        small
                        class="rounded-pill text-capitalize text-bold"
                        elevation="0"
                        color="#9bf4d5"
                        @click="postInvoiceDialog = true"
                      >
                        Generar Factura
                      </v-btn>
                      <PersonalizedDialog
                        :dialog="postInvoiceDialog"
                        :disableContinueButton="!cancelPost"
                        @save="postInvoice()"
                        @discard="
                          postInvoiceDialog = false;
                          cancelPost = false;
                        "
                        hideName
                        hideImage
                      >
                        <template #alertContent>
                          <div class="d-flex flex-column">
                            <h1 class="text-center mb-6" style="color: #35404c">
                              Generar Factura
                            </h1>
                            <p style="font-size: 1rem">
                              Está a punto de generar la factura correspondiente
                              al pago de <b>{{ getMembership.name }}</b> por
                              valor de {{ formatPrice(getMembership.price) }}. Desea
                              continuar?
                            </p>
                            <v-checkbox
                              color="success"
                              label="Proceder a generar la factura"
                              v-model="cancelPost"
                            ></v-checkbox>
                          </div>
                        </template>
                      </PersonalizedDialog>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12" v-if="memberInvoices.length != 0">
              <span style="color: #35404c; font-weight: 900; font-size: 18px"
                >Facturas generadas</span
              >
              <v-divider
                class="mt-1"
                style="background: #35404c; border: 1px solid #35404c"
              />
              <v-simple-table>
                <tbody>
                  <tr v-for="(item, index) in memberInvoices" :key="index">
                    <td class="text-bold">
                      {{ item.billingInformation.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.total) }}
                    </td>
                    <td :class="tableRowClass">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            icon
                            @click="
                              getInvoiceFile({
                                invoice: item.invoice,
                                download: false,
                              })
                            "
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Visualizar factura</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            icon
                            @click="
                              getInvoiceFile({
                                invoice: item.invoice,
                                download: true,
                              })
                            "
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar Factura</span>
                      </v-tooltip>

                      <v-tooltip bottom :disabled="item.status == 'CANCELLED'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            :disabled="item.status == 'CANCELLED'"
                            @click="cancelDialog = true"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon>mdi-cancel</v-icon>
                          </v-btn>
                        </template>
                        <span>Cancelar Factura</span>
                      </v-tooltip>
                    </td>
                    <PersonalizedDialog
                      :dialog="cancelDialog"
                      :disableContinueButton="!cancelAuth"
                      @save="deleteInvoice(item, index)"
                      @discard="
                        cancelDialog = false;
                        cancelAuth = false;
                      "
                      hideName
                      hideImage
                    >
                      <template #alertContent>
                        <div class="d-flex flex-column">
                          <h1 class="text-center mb-6" style="color: #35404c">
                            Cancelar Factura
                          </h1>
                          <p style="font-size: 1.07rem">
                            Está a punto de cancelar la factura correspondiente
                            al concepto de
                            <b>{{ item.billingInformation.description }}</b
                            >. Por favor, complete el siguiente campo para
                            proceder con la cancelación.
                          </p>
                          <v-checkbox
                            color="success"
                            label="Estoy de acuerdo con la cancelación de la factura."
                            v-model="cancelAuth"
                          ></v-checkbox>
                        </div>
                      </template>
                    </PersonalizedDialog>
                    <v-dialog
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      v-model="fileDialog"
                    >
                      <v-btn
                        v-if="$vuetify.breakpoint.smAndDown != true"
                        bottom
                        right
                        absolute
                        fab
                        color="accent"
                        :style="{
                          position: 'absolute',
                          bottom: '50px',
                          right: '50px',
                        }"
                        @click="fileDialog = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <v-btn v-else @click="fileDialog = false" large bottom
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <iframe
                        type="application/pdf"
                        :src="invoiceUrl"
                        style="width: 100%; height: 100%; border: none"
                      ></iframe>
                    </v-dialog>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </PersonalizedDialog>
</template>
<script>
import { homologateStatusWithColor } from '@/common/hook/useHelper.js';
import PersonalizedDialog from '@/components/shared/PersonalizedDialog.vue';
import { downloadFile } from '@/utils';
import { formatPrice } from '../../../utils';
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    PersonalizedDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object,
    },
  },
  data() {
    return {
      memberInvoices: [],
      invoiceUrl: '',
      fileDialog: false,
      cancelDialog: false,
      postInvoiceDialog: false,
      cancelAuth: false,
      cancelPost: false,
      tableRowClass: 'd-flex justify-end align-center',
    };
  },
  methods: {
    homologateStatusWithColor,
    formatPrice,
    ...mapMutations('loader', ['loading', 'loaded']),
    ...mapMutations('notification', ['show']),

    closeDialog() {
      this.$emit('closeDialog');
    },

    async getMemberInvoice() {
      try {
        this.loading();
        let { assignedMembership = {}, membershipPrice = 0 } =
          this.member || {};
        if (assignedMembership.id && membershipPrice) {
          let {
            data: { invoice },
            status,
          } = await this.axios({
            method: 'GET',
            url: '/invoices/member-invoice',
            params: {
              userId: this.member._id,
              organizationId: this.user._id,
              affiliationType: this.member.assignedMembership.id,
              price: this.member.membershipPrice,
            },
          });
          if (status == 200) this.memberInvoices = invoice;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },

    async postInvoice() {
      try {
        this.loading();
        let {
          status,
          data: { invoice, message },
        } = await this.axios({
          method: 'POST',
          url: '/invoices/member-invoice',
          data: {
            userId: this.member.id,
            organizationId: this.user.id,
            affiliationType: this.member.assignedMembership.id,
          },
        });
        if (status == 200) {
          this.memberInvoices.push(invoice);
          this.show({
            text: message,
            color: 'success',
            time: 3000,
          });
        }
      } catch ({
        response: {
          data: { error },
        },
      }) {
        let errorMessage = error.message
          ? error.message
          : 'Ha ocurrido un error al momento de generar la factura.';
        this.show({
          text: errorMessage,
          color: 'error',
          time: 3000,
        });
      } finally {
        this.loaded();
      }
    },

    async deleteInvoice(item, index) {
      try {
        this.loading();
        console.log(index);
        let {
          status,
          data: { invoice, message },
        } = await this.axios({
          method: 'DELETE',
          url: '/invoices/member-invoice',
          data: {
            id: item._id,
            invoiceId: item.invoice,
            organizationId: this.user.id,
          },
        });
        if (status == 200) {
          this.memberInvoices[index].status = 'CANCELLED';
          this.show({
            text: message,
            color: 'success',
            time: 3000,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },

    async getInvoiceFile({ invoice, download = false }) {
      try {
        this.loading();
        const { status, data } = await this.axios({
          method: 'POST',
          url: '/invoices/download-member-invoice',
          data: {
            invoiceId: invoice,
            organizationId: this.user.id,
            download,
          },
          responseType: 'arraybuffer',
        });
        if (status === 200) {
          if (download) {
            downloadFile(data, 'application/zip', 'invoice.zip');
          } else {
            let blob = new File([data], 'invoice.pdf', {
              type: `application/pdf`,
            });
            const fileUrl = URL.createObjectURL(blob);
            this.invoiceUrl = fileUrl;
            this.fileDialog = true;
            console.log(fileUrl);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
  },
  computed: {
    ...mapState('user', ['user']),
    getMembership() {
      let { membershipPrice = 0, assignedMembership = {} } = this.member;

      return {
        name: assignedMembership.name || '',
        price: membershipPrice || 0,
      };
    },

    canGenerateInvoice() {
      return (
        this.memberInvoices.length == 0 ||
        [...this.memberInvoices].every(
          (invoice) => invoice.status == 'CANCELLED'
        )
      );
    },
  },
};
</script>
