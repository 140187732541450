<template>
    <PersonalizedDialog
      :dialog="dialog"
      :dimentions="{ width: 700 }"
      @discard="closeDialog()"
      @save="handleConfirmMember()"
    >
      <template #alertName>
        <h4>Aprobar miembro</h4>
      </template>
      <template #alertContent>
        <p class="dialog-content">
          ¿Estás seguro de que deseas aprobar al miembro
          <span style="font-weight: bold;"> {{ getMemberFullName }}</span
          >?
        </p>
      </template>
    </PersonalizedDialog>
  </template>
  <script>
  import { homologateStatusWithColor } from "@/common/hook/useHelper.js";
  import PersonalizedDialog from "@/components/shared/PersonalizedDialog.vue";
  import { downloadFile } from "@/utils";
  import { mapState, mapMutations } from "vuex";
  export default {
    components: {
      PersonalizedDialog,
    },
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
      member: {
        type: Object,
      },
    },
    data() {
      return {
        tableRowClass: "d-flex justify-end align-center",
      };
    },
    methods: {
      homologateStatusWithColor,
      ...mapMutations("loader", ["loading", "loaded"]),
      ...mapMutations("notification", ["show"]),
      closeDialog() {
        this.$emit("closeDialog");
      },
      handleConfirmMember() {
        this.$emit("handleConfirm");
      },
    },
    computed: {
      ...mapState("user", ["user"]),
      getMemberFullName() {
        const { name = "", firstLastname = "" } = this.member;
        return `${name} ${firstLastname}`;
      },
    },
  };
  </script>
  <style scoped>
  .dialog-content {
    font-size: 1rem;
  }
  </style>  