<template>
  <div>
    <MembersNew></MembersNew>
  </div>
</template>

<script>
import Members from './Members.vue'
import MembersNew from './MembersNew.vue'
import {mapState} from 'vuex'
export default {
  components: {
    Members,
    MembersNew
  },
  computed: {
    ...mapState('user', ['user']),
    isAmpi() {
      //dev ampi: 62b622c66a7441ae20159d7d
      const ampi1 = '62b622c66a7441ae20159d7d'
      const ampi2 = '6297b0fec7bf8b6f826313be'
      if(this.user.role === 'BRANCH'){
        return this.user.headOffice === ampi1 || this.user.headOffice === ampi2
      }
      return this.user._id === ampi1 || this.user._id === ampi2
    },
  },
}
</script>

<style>

</style>